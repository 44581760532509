<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      />
    </div>

    <div v-else>
      <v-row class="mb-1">
        <v-col cols="12" md="8">
          <div class="mb-0 mb-md-6 d-flex align-center">
            <div class="text-h6  mr-3">{{tempBranch.name}}</div>
            <div>
              <v-card flat class="rounded-md px-3 py-0" :color="tempBranch.status==='Active' ? '#66d1a5' : 'rgba(254, 226, 226, 1)'">
                <span class="text-body-1 black--text">{{ tempBranch.status }}</span>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="d-flex flex-row-reverse ml-6 ml-md-0">
          <v-dialog
            v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text color="primary">
                <v-icon left>mdi-plus</v-icon>
                <span class="text-button">Add New Staff</span>
              </v-btn>
            </template>
            <v-card color="#212429">
              <v-toolbar
                color="#151D21"
                class="px-0 px-md-16"
                style="position: sticky; top: 0; z-index: 999"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title>
                  <span class="headline">Staff Info</span>
                </v-card-title>
              </v-toolbar>

              <v-card-text class="px-3">
                <v-container class="px-0">
                  <StaffForm :isDialog="true" :branch="[branch]" @staffInfo="getStaffInfo($event)" class="mx-0 mx-md-7"/>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>


          <v-dialog
            v-model="dialog1"
            fullscreen
            transition="dialog-bottom-transition"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="onEditBranch" v-bind="attrs" v-on="on" text color="primary">
                <v-icon small left>mdi-pencil</v-icon>
                <span class="text-button">Edit Information</span>
              </v-btn>
            </template>
            <v-card color="#212429">
              <v-toolbar
                color="#151D21"
                class="px-0 px-md-16"
                style="position: sticky; top: 0; z-index: 999"
              >
                <v-btn
                  icon
                  dark
                  @click="dialog1 = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title>
                  <span class="headline">Branch Info</span>
                </v-card-title>
              </v-toolbar>

              <v-card-text class="px-3">
                <v-container class="px-0">
                  <BranchForm :isDialog1="true" :editBranch="tempBranch" :editSettings="branchSettings" @branchEditInfo="getBranchEdit($event)" class="mx-0 mx-md-7"/>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

        </v-col>
      </v-row>

      <div v-if="!noStaff">
        <div v-for="user in users" :key="user.id" class="mb-6">
          <v-row align="center">
            <v-col cols="8" md="10">
              <v-avatar color="secondary" size="40" class="mr-4">
                <span>{{$shortForm(user.name)}}</span>
              </v-avatar>
              <span class="text-body-1">{{user.name}}</span>
              <div class="text-body-1 ml-14 grey--text grey--text">{{user.email}}</div>
            </v-col>
            <v-col cols="4" md="2" class="text-right">
              <v-btn @click="openDialog(user)" text class="text-capitalize">
                <span class="text-subtitle-1">{{user.roles[0].name}}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-dialog
          v-model="dialogStaff"
          fullscreen
          transition="dialog-bottom-transition"
          >
          <v-card color="#212429">
            <v-toolbar
              color="#151D21"
              class="px-0 px-md-16"
              style="position: sticky; top: 0; z-index: 999"
            >
              <v-btn
                icon
                dark
                @click="dialogStaff = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-card-title>
                <span class="headline">Staff Info</span>
              </v-card-title>
            </v-toolbar>

            <v-card-text class="px-3">
              <v-container class="px-0">
                <StaffForm :isDialogStaff="true" isEdit="true" :user="singleUser" @staffEditInfo="getStaffEdit" class="mx-0 mx-md-7"/>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div v-else class="text-center mb-8">
        <div class="text-h6 grey--text">There is no staff registered</div>
      </div>
    </div>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import store from '../../../store'
import StaffForm from '../../../components/StaffForm'
import BranchForm from '../../../components/BranchForm'
export default {
  props: ['branch', 'tenantSlug', 'id'],
  components: {
    StaffForm,
    BranchForm
  },
  data() {
    return {
      tempBranch: {},
      branchSettings: {},
      singleUser:'',
      users: [],
      shortName: [],
      loading: true,
      noStaff: false,
      dialog: false,
      dialog1: false,
      dialogStaff: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    }
  },

  mounted() {
    // console.log('edit branch branchInfo-', this.branch)
    // console.log('store theke users', store.getters.User)
    // console.log(this.branch.id, this.tenantSlug)
    this.tempBranch = this.branch
    this.getStaff()
    this.getSingleBranch()
  },

  methods: {
    getStaffEdit(res) {
      this.getStaff()
      this.dialogStaff = false
      // console.log('getting response from staff', res)
      this.snackbar = true
      this.snackbarColor = res.snackbarColor
      this.snackbarText = res.snackbarText
    },

    getBranchEdit(res) {
      this.getSingleBranch()

      this.dialog1 = false
      // console.log('getting response from branch', res)
      this.snackbar = true
      this.snackbarColor = res.snackbarColor
      this.snackbarText = res.snackbarText
    },

    getSingleBranch() {
      const payload = {
        branchId: this.branch.id,
        tenantSlug: this.tenantSlug
      }
      store.dispatch('getSingleBranch', payload)
      .then(res => {
        // console.log('single Branch', res)
        this.tempBranch = res.data.data.branches.branch
        this.branchSettings = res.data.data.branches.settings
        // console.log('branch name up', this.branch.name)
      })
    },

    getStaff() {
      const payload = {
        branchId: this.branch.id,
        tenantSlug: this.tenantSlug
      }
      // console.log('payload', payload)
      store.dispatch('getStaff', payload)
      .then(res => {
        // console.log('users -', res.data.data.branches.users)
        this.users = res.data.data.branches.users
        // console.log('users name', this.users)
        this.loading = false
        // console.log(this.users)
        if(this.users == '') {
          // console.log('This branch has no staff')
          this.noStaff = true
        }
      })
    },

    onEditBranch() {
      // console.log('clicked',this.branch)
      // this.getSingleBranch()
      this.dialog1 = true
    },

    openDialog(user) {
      // console.log('dsds', user)
      this.singleUser = user
      this.dialogStaff = true
    },
    getStaffInfo(res) {
      if (res.status) {
        console.log('error')
      }else {
        const payload = {
        branchId: this.branch.id,
        tenantSlug: this.tenantSlug
      }
      store.dispatch('getStaff', payload)
      .then(res => {
        // console.log('users -', res.data.data.branches.users)
        const len = res.data.data.branches.users.length
        this.users.push(res.data.data.branches.users[len-1])
        this.loading = false
        this.noStaff = false
        // console.log('users',this.users)
      })
      }

      this.snackbar = true
      this.dialog = res.dialog
      this.snackbarColor = res.snackbarColor
      this.snackbarText = res.snackbarText
    }
  }
}
</script>
