<template>
  <div class="mx-3 mx-md-6 mb-6">
    <div v-if="loading" class="pt-8 text-center">
      <v-progress-circular
        :size="80"
        color="primary"
        indeterminate
      />
      <p class="pt-8">
        Loading...
      </p>
    </div>

    <div v-else>
      <div v-if="!noBranch">
        <v-dialog
          v-model="dialog"
          fullscreen
          transition="dialog-bottom-transition"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text class="primary--text">
              <v-icon left>mdi-plus</v-icon>
              <span class="text-button">Add New Branch</span>
            </v-btn>
          </template>
          <v-card color="#212429">
            <v-toolbar
              color="#151D21"
              class="px-0 px-md-16"
              style="position: sticky; top: 0; z-index: 999"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-card-title>
                <span class="headline">Branch Info</span>
              </v-card-title>
            </v-toolbar>

            <v-card-text class="px-3">
              <v-container class="px-0">
                <BranchForm :isDialog="true" @branchInfo="getBranchInfo($event)" class="mx-0 mx-md-7"/>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>


        <v-card v-for="branch in branches" :key="branch.id" class="mt-6 pa-6 card">
          <Staff :branch="branch" :tenantSlug="singleTenant.slug" :id="branch.id"/>
        </v-card>
      </div>

      <div v-else class="text-center mt-12">
        <div class="text-h6 grey--text mb-4">There is no branch registered</div>

        <v-dialog
          v-model="dialog"
          fullscreen
          transition="dialog-bottom-transition"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-large class="primary--text">
              <v-icon left>mdi-plus</v-icon>
              <span class="text-button">Add New Branch</span>
            </v-btn>
          </template>
          <v-card color="#212429">
            <v-toolbar
              color="#151D21"
              class="px-0 px-md-16"
              style="position: sticky; top: 0; z-index: 999"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-card-title>
                <span class="headline">Branch Info</span>
              </v-card-title>
            </v-toolbar>

            <v-card-text class="px-3">
              <v-container class="px-0">
                <BranchForm :isDialog="true" @branchInfo="getBranchInfo($event)" class="mx-0 mx-md-7"/>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      timeout="3000"
      top
      right
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import store from '../../../store'
import Staff from './Staff'
import BranchForm from '../../../components/BranchForm'

export default {
  props: ['singleTenant'],
  components: {
    Staff,
    BranchForm,
  },
  data() {
    return {
      branches: [],
      loading: true,
      noBranch: false,
      dialog: false,
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
    }
  },
  mounted() {
    store.dispatch('getBranch', this.singleTenant.slug)
    .then(res => {
      this.loading = false
      this.branches = res.data.data.branches.branches
      // console.log(this.branches)
      if(this.branches == '') {
        // console.log('This restaurant has no branch')
        this.noBranch = true
      }
    })
  },

  methods: {
    getBranchInfo(res) {
      if(res.error) {
        // console.log('Error in branch add')
      } else {
        store.dispatch('getBranch', this.singleTenant.slug)
        .then(res => {
          this.loading = false
          const len = res.data.data.branches.branches.length
          this.branches.push(res.data.data.branches.branches[len-1])
          // console.log('after push >>> ', this.branches)
          this.noBranch = false
          // console.log(this.branches)
        })
      }

      // console.log(this.branches)
      this.snackbar = true
      this.dialog = res.dialog
      this.snackbarColor = res.snackbarColor
      this.snackbarText = res.snackbarText
    }
  }
}
</script>

