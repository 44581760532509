var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-3 mx-md-6 mb-6"},[(_vm.loading)?_c('div',{staticClass:"pt-8 text-center"},[_c('v-progress-circular',{attrs:{"size":80,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"pt-8"},[_vm._v(" Loading... ")])],1):_c('div',[(!_vm.noBranch)?_c('div',[_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"text-button"},[_vm._v("Add New Branch")])],1)]}}],null,false,3131839097),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"#212429"}},[_c('v-toolbar',{staticClass:"px-0 px-md-16",staticStyle:{"position":"sticky","top":"0","z-index":"999"},attrs:{"color":"#151D21"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Branch Info")])])],1),_c('v-card-text',{staticClass:"px-3"},[_c('v-container',{staticClass:"px-0"},[_c('BranchForm',{staticClass:"mx-0 mx-md-7",attrs:{"isDialog":true},on:{"branchInfo":function($event){return _vm.getBranchInfo($event)}}})],1)],1)],1)],1),_vm._l((_vm.branches),function(branch){return _c('v-card',{key:branch.id,staticClass:"mt-6 pa-6 card"},[_c('Staff',{attrs:{"branch":branch,"tenantSlug":_vm.singleTenant.slug,"id":branch.id}})],1)})],2):_c('div',{staticClass:"text-center mt-12"},[_c('div',{staticClass:"text-h6 grey--text mb-4"},[_vm._v("There is no branch registered")]),_c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"x-large":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',{staticClass:"text-button"},[_vm._v("Add New Branch")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"#212429"}},[_c('v-toolbar',{staticClass:"px-0 px-md-16",staticStyle:{"position":"sticky","top":"0","z-index":"999"},attrs:{"color":"#151D21"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Branch Info")])])],1),_c('v-card-text',{staticClass:"px-3"},[_c('v-container',{staticClass:"px-0"},[_c('BranchForm',{staticClass:"mx-0 mx-md-7",attrs:{"isDialog":true},on:{"branchInfo":function($event){return _vm.getBranchInfo($event)}}})],1)],1)],1)],1)],1)]),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":"3000","top":"","right":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }